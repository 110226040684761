/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $('.gallery-link').magnificPopup({
          type: 'image',
          gallery: { 
            enabled:true
          }
        });
        $('.share-link').click(function(e) {
            e.preventDefault();
            var url = $(this).attr('href');
            if ($(this).attr('data-type') === 'google') {
                var width = 400;
                var height = 490;
            } else if ($(this).attr('data-type') === 'pinterest') {
                var width = 765;
            } else {
              var width = 550;
              var height = 650;
            }
            window.open(url, "popupWindow", "width=" + width + ", height=" + height + ", scrollbars=yes");
        });
        $('.btn-collapse').click(function($) {
          $('.btn-collapse').toggleClass('d-none');
          $('.collapsible-info').toggleClass('is-expanded');
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        $('.carousel-control-prev').on('click', function() {
          $('#home-slider').carousel('prev');
        });
        $('.carousel-control-next').on('click', function() {
          $('#home-slider').carousel('next');
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Properties
    'single_properties': {
      init: function() {
        // JavaScript to be fired on Properties
        $('#property-details').on('show.bs.collapse', function () {
          $('.show-more-link').hide();
          $('.show-less-link').show();
        });
        $('#property-details').on('hide.bs.collapse', function () {
          $('.show-more-link').show();
          $('.show-less-link').hide();
        });
        $('#toggleRequestShowing').change(function(){
            if (this.checked) {
              $('#requestShowing').show();
            } else {
              $('#requestShowing').hide();
            }
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
